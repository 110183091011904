import { createApp } from 'vue';
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.variable.min.css';

import App from './App.vue';
import router from './router';
import store from './store';
(window as any).domain = 'qq.com';
document.domain = 'qq.com';
router.beforeEach(async (to, from, next) => {
  // 登录判断
  if (!store.state.status.login) {
    await store.dispatch({
      type: 'getUserInfo',
    });
  }
  next();
});

createApp(App)
  .use(store)
  .use(router)
  .use(Antd)
  .mount('#app');
