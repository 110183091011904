import axios from 'axios';
import store from '@/store';
import { message } from 'ant-design-vue';
import jsCookie from 'js-cookie';
import qs from 'qs';
message.config({
  top: '80px',
});
axios.defaults.withCredentials = true;

const api_version = jsCookie.get('api-version');

axios.interceptors.request.use((config: any) => {
  // config.headers['api-version'] = api_version || '116345419';
  if (config.method === 'post') {
    config.data = qs.stringify(config.data);
  }

  config.requestTime = new Date().getTime();
  return config;
});

// 设置拦截器
axios.interceptors.response.use(
  (res) => {
    // store.state.apiEnv = res.headers.Env;
    res.data.data = res.data.data || {};
    let code: any = '';
    if (res.data && res.data.code == 0) {
      code = 0;
      if (res.data.mock) {
        console.warn(res);
      }
    } else if (res.data.code) {
      code = res.data.code;
    } else {
      code = '-';
    }
    // 前端监控上报
    // common.dataReport('reqreport', {
    //     url: res.config.url,
    //     method: res.config.method,
    //     status: res.status ? res.status : '-',
    //     ret_code: code,
    //     req_time: new Date().getTime() - res.config.requestTime
    // })
    if (code === '-') {
      return res;
    }
    // 未登录去登录
    if (res.data.msg === '用户未登录' || res.data.info === '用户未登录') {
      store.state.status.login = false;
      store.state.userInfo = {};
    } else if (res.data.code !== 0) {
      message.error(res.data.msg || res.data.info);
    }
    return res;
  },
  (err) => {
    if (err.response) {
      console.log(err.response);

      switch (err.response.status) {
        case 500:
          message.error(err.response.data.msg
            ? err.response.data.msg
            : '系统错误');
          break;
        case 401: // 返回401 未登录
          // store.state.status.login = false;
          // store.state.userInfo = {};
          break;
        default:
          message.error(err.response.data.msg
            ? err.response.data.msg
            : err.response.data);
      }
    }
    return Promise.resolve(err || {});
  },
);
export default axios;
