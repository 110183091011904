/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable import/no-mutable-exports */
/**
 * services 域名接口配置
 * urlPrefix 接口前缀
 */

// let urlPrefix = `${document.location.protocol}//passport.ctp.qq.com/api`;
let urlPrefix = `${document.location.protocol}//${document.location.hostname}/api`;
if (process.env.NODE_ENV === 'production') {
  urlPrefix = `${document.location.protocol}//${document.location.hostname}/api`;
} else {
  urlPrefix = 'https://tpassport.iheat.qq.com/api';
}

export { urlPrefix };
