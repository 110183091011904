import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'login',
    meta: { title: '登录' },
    component: () => import(/* webpackChunkName: "login" */ '../views/login/login.vue'),
  },
  {
    path: '/callback/:type',
    name: 'login-callback',
    meta: { title: '登录' },
    component: () => import(
      /* webpackChunkName: "login-callback" */ '../views/login/callback.vue'),
  },
  {
    path: '/loginComponent',
    name: 'loginComponent',
    meta: { title: '登录' },
    component: () => import(
      /* webpackChunkName: "login" */ '../components/Login/LoginComponent.vue'),
  },
];
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
