import axios from './axios';
import { urlPrefix } from './config';

// axios示例
// export const ArticleSaveVideo = (params?:any) => axios.post(`${urlPrefix}/articles/activity`, params);

// QQ
export const QQLogin = (params?: any) => axios.get(`${urlPrefix}/login/qq/url`, { params });
export const QQLoginItea = (params?: any) => axios.get(`${urlPrefix}/login/qq/login_url`, { params });

// QQ绑定
export const BindQQUrl = (params?: any) => axios.get(`${urlPrefix}/bind/qq/url`, { params });

// QQ解绑
export const UnbindQQUrl = (params?: any) => axios.get(`${urlPrefix}/unbind/qq/url`, { params });

// QQ钱包认证
export const QQPayQQUrl = (params?: any) => axios.get(`${urlPrefix}/qqpay/qq/url`, { params });

// 微信登录
export const WXLogin = (params?: any) => axios.get(`${urlPrefix}/login/wx/url`, { params });
export const WXLoginItea = (params?: any) => axios.get(`${urlPrefix}/login/wx/login_url`, { params });

// 绑定微信
export const BindWxUrl = (params?: any) => axios.get(`${urlPrefix}/bind/wx/url`, { params });

// 解绑微信
export const UnbindWxUrl = (params?: any) => axios.get(`${urlPrefix}/unbind/wx/url`, { params });

// QQ
export const QQLoginCallback = (params?: any) => axios.post(`${urlPrefix}/login/qq/callback`, params);

// 微信登录
export const WXLoginCallback = (params?: any) => axios.post(`${urlPrefix}/login/wx/callback`, params);

// 手机绑定
export const BindMobile = (params?: any) => axios.post(`${urlPrefix}/bind/mobile`, params);

// 用户信息
export const UserInfo = (params?: any) => axios.get(`${urlPrefix}/user/info`, { params });

// 退出登录
export const LogOut = (params?: any) => axios.get(`${urlPrefix}/logout`, { params });

// 手机验证码或密码登录
export const LoginMobile = (params?: any) => axios.post(`${urlPrefix}/login/mobile`, params);

// 发送短信、邮箱验证码
export const SendVerify = (params?: any) => axios.post(`${urlPrefix}/send/verify`, params);

// 游客创建新用户
export const GuestCreate = (params?: any) => axios.post(`${urlPrefix}/guest/create`,  params);

// 游客绑定QQ用户
export const GuestBindQQUrl = (params?: any) => axios.get(`${urlPrefix}/guest/bind/qq/url`, { params });

// 游客绑定微信
export const GuestBindWxUrl = (params?: any) => axios.get(`${urlPrefix}/guest/bind/wx/url`, { params });

// 邮箱登录
export const LoginEmail = (params?: any) => axios.post(`${urlPrefix}/login/email`, params);

// 邮箱绑定
export const BindEmail = (params?: any) => axios.post(`${urlPrefix}/bind/email`, params);

// 邮箱解绑
export const UnbindEmail = (params?: any) => axios.post(`${urlPrefix}/unbind/email`, params);

// 密码重置
export const PasswordReset = (params?: any) => axios.post(`${urlPrefix}/password/reset`, params);

