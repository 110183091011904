
import { defineComponent,  onBeforeMount,  reactive } from 'vue'
import  { ConfigProvider } from 'ant-design-vue';
export default defineComponent({
    setup() {
        const queryMap: {
            themeColor?: string
        } = reactive(getQueryMap()); // 地址栏参数

        onBeforeMount(() => {
            if(location.search.includes('themeColor')){
                setTheme(decodeURIComponent(queryMap.themeColor) || '')
            }
        })

        function getQueryMap(): Object{
            let queryList = encodeURIComponent(location.href).split('%26');
            let listMap: any = {};
            queryList.forEach(v => {
                let queryItem = v.split('%3D')
                listMap[decodeURIComponent(queryItem[0])] = decodeURIComponent(queryItem[1])
            })
            return listMap;
        }
        
        function setTheme(color: string){
            ConfigProvider.config({
                theme: {
                    primaryColor: color,
                },
            });
        }
    },
})
