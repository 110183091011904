import { UserInfo } from '@/services/getData';
export default {
  async getUserInfo(store: any) {
    console.log(store);
    const { commit } = store;
    const res = await UserInfo();
    if (res.status === 200 && res.data.code === 0) {
      commit('saveUserInfo', res.data.data);
    }
  },
};
