import { createStore } from 'vuex';
import actions from './actions';
import mutations from './mutations';
import getters from './getters';
console.log(mutations);
export default createStore({
  state: {
    status: {
      // 是否登录
      login: false,
      allowAccess: false,
    },
    userInfo: {},
    isIframe: window.parent !== window,
  },
  actions,
  mutations,
  getters,
  modules: {},
});
